// Header 
@import "../node_modules/@wtw/platform/styling/modules/variables";
// @import "../node_modules/@wtw/platform/styling/modules/mixins";
@import "../node_modules/bootstrap/scss/mixins";
body,
* {
    font-family: Neue Haas Grotesk, lt, sans-serif !important;
}

#MathJax_Message {
    display: none;
}

.overlay {
    z-index: 999999;
    position: fixed;
}

.row-hr {
    display: none;
}

.stepper-container {
    display: none;
}

.bs-datepicker-body {
    .selected {
        background: #007FA7 !important;
        color: white;
    }
}

.bs-datepicker-head {
    background-color: #007FA7 !important;
}

.modal-backdrop {
    z-index: 10040;
}

.week {
    display: none;
}

.weeks {
    tr {
        th:first-child() {
            display: none;
        }
    }
}

.btn-warning {
    background-color: #D66B00;
    border-color: #D66B00;
}

.btn-secondary {
    color: #707070;
    border-color: #707070;
    &:hover {
        color: #007FA7;
        border-color: #007FA7;
    }
}

// .btn-primary {
//     background-color: #007FA7;
//     &:hover {
//         background: #00607d;
//     }
// }

a {
    color: #007FA7;
    &:hover {
        color: #00607d;
    }
    &:focus {
        color: #00607d;
    }
}

.thirdparty--toolbar--wrap {
    height: 53px;
    // @extend %theme--background--light;
    @include media-breakpoint-down(sm) {
        height: 42px;
    }
    .thirdparty--toolbar--container {
        height: 51px;
        margin: 0;
        @include media-breakpoint-down(sm) {
            height: 40px;
        }
    }
    .thirdparty--toolbar--title {
        margin-left: 15px;
        padding: 0;
    }
    .thirdparty--toolbar--title--text {
        position: relative;
        left: 32px;
        top: 8px;
        @include media-breakpoint-down(sm) {
            top: 0;
        }
    }
    .thirdparty--toolbar--title--logo {
        width: 38px;
        height: 38px;
        position: absolute;
        margin-left: -46px;
        top: -10px;
        @include media-breakpoint-down(sm) {
            width: 30px;
            top: -8px;
        }
    }
}

//  Navigation
.sidebar--vertical--wrap ul.nav-vertical {
    li {
        a {
            .icon-runs_manager:before {
                font-size: 30px;
            }
            .icon-search {
                &:before {
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
                    display: inline-block;
                }
            }
            .icon-details:before {
                font-size: 25px;
            }
        }
    }
}

.icon-close_thin:before {
    color: $WTW-Software-Gray-6;
}

.strategy-selected {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.strategy-reveal {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
    visibility: hidden;
}

@include media-breakpoint-down(md) {
    .openDiv {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
    .closeDiv {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(100%);
    }
}

.strategy-graphpoint-chosen {
    background-color: $WTW-Green-5;
    color: $white;
    padding: 5px 10px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.strategy-graphpoint-current {
    @extend .strategy-graphpoint-chosen;
    background-color: $WTW-Magenta-dark;
}

.strategy-graphpoint-recommended {
    @extend .strategy-graphpoint-current;
    background-color: $WTW-Green;
    padding: 4px 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    text-transform: uppercase;
    width: 33px;
    height: 33px;
    color: $black;
}

.circle {
    border: 2px solid $WTW-Software-Gray-6;
    border-radius: 20px;
    width: 20px;
    font-size: 12px;
    padding: 5px;
    height: 20px;
}

.view-more {
    .dropdown-content {
        top: 27px;
        z-index: 2;
        text-align: left;
    }
}

.tooltip-strategy {
    .strategy-content {
        width: 224px;
        z-index: 1;
        margin: -6px -7px;
        height: 212px;
        .title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .btn-secondary {
        background-color: transparent;
        color: $WTW-Software-Gray-5;
        &:hover,
        &:active {
            background-color: $WTW-Software-Gray-8;
            border-color: $WTW-Software-Gray-1;
            color: $WTW-Software-Gray-1;
        }
    }
}

.highcharts-tooltip {
    z-index: 9998;
}

.highcharts-tooltip-box {
    z-index: 9997;
}

.legend {
    padding: 0px 40px 0 0;
    margin: 0;
    div {
        line-height: 0.8;
        display: inline-block;
        margin-left: 20px;
        span {
            &:nth-child(2) {
                margin: 0 0 0 20px;
                font-size: 10px;
                position: relative;
                top: -2px;
            }
        }
    }
}

// Risk Type Details
.btn-group.view-list-btn {
    .btn {
        padding: 0 0 0 1rem;
        @media (min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait) {
            padding: 0 1rem 0 0;
        }
        @media (min-device-width: 812px) and (max-device-width: 375px) and (orientation: landscape) {
            padding: 0 1rem 0 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 0 1rem 0 0;
        }
        .icon-view_list,
        .icon-view_grid {
            &:before {
                font-size: 18px;
                color: $WTW-Software-Gray-6;
            }
        }
        &.active {
            .icon-view_list,
            .icon-view_grid {
                &:before {
                    color: $brand-primary;
                }
            }
        }
    }
}

.mt-b {
    @include media-breakpoint-down(sm) {
        margin: 10px 0 0;
    }
}

.list_grid-view {
    margin: 0;
    .list-details {
        width: 100%;
        padding: 0 15px;
    }
    &.grid-block {
        .col-md-6,
        .col-12 {
            margin-top: 35px;
        }
        .list-details {
            display: none;
        }
        .sub {
            display: none;
        }
    }
    &.list-block {
        &:before {
            content: '';
            border-left: 2px solid $white;
            position: absolute;
            z-index: 9999;
            height: 30px;
            left: 15px;
        }
    }
    [id^=detail] {
        margin: 0 -15px;
        .transform-active {
            &:before {
                content: '';
                width: 0px;
                height: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $WTW-Software-Gray-3;
                position: absolute;
                top: 7px;
                left: 24%;
                @include media-breakpoint-down(xs) {
                    top: auto;
                    margin-top: -8px;
                    left: 50%;
                }
            }
            &.open {
                &:before {
                    content: '';
                    left: auto;
                    right: 24%;
                }
            }
            .icon-close_thin {
                position: absolute;
                right: 30px;
                top: 30px;
                z-index: 1;
                @include media-breakpoint-down(xs) {
                    top: auto;
                    margin-top: 20px;
                }
            }
        }
    }
}

// Stability of Portfolio Efficiency
.carousel.irm-carousel {
    .highcharts-data-label {
        top: -13px !important;
    }
    .highcharts-tick {
        display: none;
    }
    .highcharts-markers {
        display: none;
    }
    .highcharts-xAxis-label {
        text {
            padding: 20px 0 0 0;
        }
    }
}

input {
    &:invalid:not(:focus):not(:placeholder-shown) {
        border-bottom: 2px solid $brand-danger !important;
        &+label {
            opacity: 0;
        }
    }
}

// walk chart styling
.strategy-graphpoint-chosen-walk {
    background-color: $WTW-Green-5;
    color: $white;
    padding: 3px 6px;
    font-size: 11px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    width: 20px;
    height: 20px;
}

.strategy-graphpoint-current-walk {
    @extend .strategy-graphpoint-chosen-walk;
    background-color: $WTW-Magenta-dark;
}

.strategy-graphpoint-recommended-walk {
    @extend .strategy-graphpoint-current-walk;
    background-color: $WTW-Green;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    text-transform: uppercase;
    color: $black;
}

.low-opacity {
    opacity: 0.2;
}

.tb-container .tb-row .tb-cell,
#comparison .tb-container .tb-row .th-cell,
.tb-container #comparison .tb-container1 .tb-row .th-cell {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: inline-block !important;
    }
}

// HighChart - Impact Column Chart
.impact-column-chart {
    .label-circle {
        border: 1px solid $WTW-Software-Gray-6;
        border-radius: 20px;
        width: 15px;
        font-size: 12px;
        padding: 5px;
        height: 15px;
        cursor: pointer;
        margin-top: 10px;
        position: relative;
        right: -4px;
    }
    .label-selected-circle {
        border: 5px solid $brand-primary;
        border-radius: 20px;
        width: 15px;
        font-size: 12px;
        height: 15px;
        cursor: pointer;
        margin-top: 10px;
        position: relative;
        right: -4px;
        &:after {
            content: '';
            position: absolute;
            left: -12px;
            top: 15px;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid $WTW-Software-Gray-1;
            clear: both;
        }
    }
    .text-bold {
        font-weight: 700;
    }
}

// HighChart - Slider Chart
.slider-chart {
    .highcharts-navigator-handle {
        stroke-width: 0;
    }
    .highcharts-navigator-mask-inside {
        fill: rgba(197, 197, 197, 0.3);
    }
    .highcharts-grid-line {
        stroke-width: 0;
    }
    .highcharts-line-series {
        visibility: hidden;
    }
}

// impact of Portfolio
.imp-legend {
    div span:nth-child(2) {
        top: -4px !important;
        margin: 0 0 0 15px !important;
        font-weight: bold;
        color: $WTW-Software-Gray-9;
    }
}

// HighChart - WaterFall Chart
#waterfall-chart {
    .highcharts-text-outline {
        display: none;
    }
    .highcharts-series-1 .highcharts-data-label-color-undefined tspan {
        stroke-width: 0;
        fill: $WTW-Software-Gray-9;
        font-size: 14px;
    }
    .highcharts-series-2 .highcharts-data-label-color-undefined tspan {
        stroke-width: 0;
        fill: $black;
        font-size: 14px;
    }
    .highcharts-legend {
        pointer-events: none;
    }
}

// filter result
.filter-results-container {
    .slider--mod .slider {
        display: block;
        width: 100%;
        height: 30px;
        padding: 60px 0 10px;
        cursor: default;
        font-size: 12px;
        .bar {
            width: 100%;
            background: $WTW-Software-Gray-5;
            height: 6px;
            position: relative;
            border-radius: 0px;
            &>span.left-handle,
            &>span.right-handle {
                width: 10px !important;
                height: 18px !important;
                background: $brand-primary !important;
                border-width: 0 !important;
                border-radius: 0 !important;
                .handle-tooltip {
                    top: -38px !important;
                    border-width: 0 !important;
                    background: $WTW-Software-Gray-9 !important;
                    border-radius: 0 !important;
                    padding: 5px 0 !important;
                    color: $white !important;
                    opacity: 1 !important;
                    min-width: 40px !important;
                    &:after,
                    &:before {
                        border-top-color: $WTW-Software-Gray-9 !important;
                        top: 100% !important;
                        left: 35% !important;
                    }
                }
            }
        }
        .values {
            display: block;
            font-weight: 400;
            margin-top: 4px;
            margin-left: -1%;
            color: $WTW-Software-Gray-9;
        }
        div.filler {
            border-width: 0 !important;
            border-radius: 0 !important;
            &>span {
                background: $WTW-Software-Gray-8 !important;
            }
        }
    }
}

// Cloud
.graph {
    .legend {
        text-align: right;
        white-space: nowrap;
        @include media-breakpoint-down(xs) {
            text-align: center;
            padding-right: 0;
        }
        div {
            span {
                &:nth-child(2) {
                    margin: 0 0 0 20px;
                    font-size: 12px;
                    position: relative;
                    top: 2px;
                }
            }
            .current {
                padding: 7px;
                border-radius: 0;
                background-color: $WTW-Magenta-dark;
                position: absolute;
            }
            .selected {
                @extend .current;
                background-color: $WTW-Green-5;
            }
            .recommended {
                @extend .current;
                background-color: $WTW-Green;
                border-radius: 7px;
            }
        }
    }
}

.top {
    .filter-results-container {
        @include media-breakpoint-up(lg) {
            top: 41px !important;
            height: calc(100vh - 175px) !important;
        }
        @include media-breakpoint-up(xl) {
            top: 110px !important;
            height: calc(95vh - 175px) !important;
        }
        @media (min-width: 1300px) and (max-width: 1366px) {
            top: 70px !important;
            height: calc(97vh - 157px) !important;
        }
    }
}

.top .sideBar-container {
    @include media-breakpoint-up(lg) {
        top: 41px !important;
        height: calc(100vh - 175px) !important;
        min-height: 498px !important;
    }
    @include media-breakpoint-up(xl) {
        top: 110px !important;
        height: calc(95vh - 175px) !important;
        min-height: unset !important;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
        top: 70px !important;
        height: calc(97vh - 157px) !important;
        min-height: unset !important;
    }
}

.portfolio-strategies {
    .ps-show-active {
        min-height: 50vh;
        @include media-breakpoint-down(md) {
            min-height: 100vh;
        }
    }
}

.sideBar-container {
    .ps-show-active {
        max-height: 100vh;
        min-height: 75vh;
    }
}

// ACL 
.filter-portfolio {
    top: 80px;
    ul {
        &.list {
            li {
                margin: 0 0 5px 10px;
            }
        }
    }
}

.modal {
    z-index: 10500;
}

.apply-filter {
    .ps {
        max-height: 60vh;
        @include media-breakpoint-down(md) {
            max-height: 72vh;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 376px) {
            max-height: 53vh !important;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 575px) {
            max-height: 60vh !important;
        }
    }
}

.filter-scroll {
    .ps {
        max-height: 76vh;
    }
}

.ps .ps__rail-y:hover,
.ps .ps__rail-x:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-x:focus {
    background-color: transparent !important;
}

.filter-button {
    position: absolute !important;
    bottom: 15px !important;
    width: 90% !important;
    @include media-breakpoint-down(md) {
        bottom: 0 !important;
    }
}

.ps-padding {
    padding: 15px 0px;
    max-height: 100vh;
}